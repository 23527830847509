import { Module } from "vuex";
import { Fetcher as Ajax } from "@/utils/Request";

import {
  API_USER,
  API_USER_DISABLE,
  API_USER_ENABLE,
  API_USER_TREE
} from "@/configs/Apis";

import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";

import baseModule from "./common";

const newModule: Module<any, any> = merge(cloneDeep(baseModule), {
  actions: {
    getUserTree({ commit }: any) {
      return Ajax.queryData(API_USER_TREE, {}, "GET", false)
        .then(data => {
          let count = 0;
          function execCount(item: any) {
            if (item && item.children) {
              item.children.forEach((child: any) => {
                count += child.count;
              });
            }
          }
          if (data && data.length) {
            execCount(data[0]);
          }
          commit(
            "setUserTree",
            [
              {
                title: "全部",
                count: count,
                icon: "all-data",
                userFilter: {}
              }
            ].concat(data[0].children)
          );
        })
        .catch(() => {
          commit("setUserTree", [
            {
              title: "全部",
              count: 0,
              icon: "all-data",
              userFilter: {}
            }
          ]);
        });
    },
    addUser(store: any, payload: any) {
      return Ajax.saveData(API_USER, payload, "POST");
    },
    updateUser(store: any, payload: any) {
      return Ajax.saveData(API_USER + "/" + payload.id, payload, "PUT");
    },
    deleteUser(store: any, userFilter: any) {
      return Ajax.saveData(API_USER, userFilter, "DELETE");
    },
    disableUser(store: any, userFilter: any) {
      return Ajax.saveData(API_USER_DISABLE, userFilter, "PUT");
    },
    enableUser(store: any, userFilter: any) {
      return Ajax.saveData(API_USER_ENABLE, userFilter, "PUT");
    }
  },
  mutations: {
    setUserTree(state: any, data: any) {
      state.userTree = data;
    }
  }
});

const oldState = newModule.state as () => Object;

newModule.state = () => ({ ...oldState(), userTree: null });

export default newModule;
