import { API_PATENT_ADD_BY_OPEN_API_TASK } from "@/configs/Apis";
import { isEmptyOrWhiteSpace, showError } from "@/utils/Common";
import { Fetcher } from "@/utils/Request";
import { MessageBox } from "element-ui";
import { Module } from "vuex";

declare interface PatentSync {
  taskId: number;
  progress: {
    status: string;
    total: number;
    finished: number;
    succeed: number;
    rest: number;
    content: string;
  };
  taskToken: any;
}

const defaultProgress = {
  status: "NOT_STARTED",
  total: -1,
  finished: 0,
  succeed: 0,
  rest: 0,
  content: ""
};

const storeOption: Module<PatentSync, any> = {
  namespaced: true,
  state: {
    taskId: 0,
    progress: JSON.parse(JSON.stringify(defaultProgress)),
    taskToken: null
  },
  getters: {
    display(state: PatentSync) {
      switch (state.progress.status) {
        case "NOT_STARTED":
          return "排队中...";
        case "EXECUTING":
        case "COMPLETED":
          return `总数：${state.progress.total}，完成：${state.progress.succeed}`;
        case "ABEND":
          return `任务异常终止：${state.progress.content}，总数：${state.progress.total}，完成：${state.progress.succeed}`;
        default:
          return `ERROR STATUS ${state.progress.status}`;
      }
    },
    isSync(state: PatentSync) {
      return !!state.taskId;
    }
  },
  mutations: {
    taskId(state: PatentSync, taskId: number) {
      state.taskId = taskId;
    },
    progress(state: PatentSync, data: any) {
      state.progress = data;
    },
    taskToken(state: PatentSync, token: string) {
      state.taskToken = token;
    }
  },
  actions: {
    setTaskId({ commit, rootGetters }, taskId) {
      const userId = (rootGetters["user/user"] || {}).id;
      if (taskId) {
        window.localStorage.removeItem(`patentsync-taskid-${userId}`);
        window.localStorage.setItem(`patentsync-taskid-${userId}`, taskId);
      } else {
        window.localStorage.removeItem(`patentsync-taskid-${userId}`);
      }
      commit("taskId", taskId);
    },
    queryProgress({ commit, dispatch, state }) {
      if (!isEmptyOrWhiteSpace(state.taskToken)) {
        return;
      }
      if (isEmptyOrWhiteSpace(state.taskId)) {
        return;
      }

      function doQuery() {
        if (!state.taskId) {
          return;
        }
        Fetcher.queryData(
          `${API_PATENT_ADD_BY_OPEN_API_TASK}/${state.taskId}`,
          {},
          "GET",
          false
        )
          .then(data => {
            if (data.status === "COMPLETED") {
              if (!isEmptyOrWhiteSpace(data.errorMessage)) {
                showError(data.errorMessage);
              } else {
                MessageBox.alert(
                  `<ul style="margin: 0">
                                <li>本次添加的专利数量为${data.succeed}</li>
                                <li>本次检索的专利已全部添加，如果添加数量与检索数量不一致，可能是由于已存在相同的申请号</li>
                                </ul>`,
                  "提示",
                  {
                    dangerouslyUseHTMLString: true,
                    callback: () => {
                      window.location.reload();
                    }
                  }
                );
              }
              clearInterval(state.taskToken);
              commit("taskToken", null);
              commit("progress", JSON.parse(JSON.stringify(defaultProgress)));
              dispatch("setTaskId", 0);
            } else {
              commit("progress", data);
              commit("taskToken", window.setTimeout(doQuery, 3000));
            }
          })
          .catch(console.error);
      }

      doQuery();

      commit("taskToken", -99);
    }
  }
};

export default storeOption;
