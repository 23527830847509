





























































































































































import DItemSelector from "@/components/DItemSelector.vue";
import { isEmpty, isEmptyOrWhiteSpace, showError } from "@/utils/Common";
import { TableColumn } from "element-ui/types/table-column";
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: { DItemSelector }
})
export default class DList extends Vue {
  selectedCount = 0;
  pageIndex = 1;
  pageSize = 20;

  orderProp = "";

  selectedRows: Map<string, any> = new Map<string, any>();

  selectedOneRow = "";

  showColumnConfigDialog = false;

  @Prop({ type: Boolean, default: () => true })
  canChangePageSize!: boolean;

  @Prop({ type: Boolean, default: () => true })
  showSearchPanel!: boolean;

  @Prop({ type: Number, default: () => 20 })
  defaultPageSize!: number;

  @Prop({ type: Number, default: () => 0 })
  actionColumnWidth!: number;

  @Prop({ required: true, type: String, default: () => "{}" })
  queryParams!: string;

  @Prop({ required: true, type: String })
  dataApi!: string;

  @Prop({ type: Boolean, default: () => true })
  autoLoad!: boolean;

  @Prop({ type: Boolean, default: () => true })
  showSelectedTooltip!: boolean;

  @Prop({ type: String, default: () => "id" })
  rowIdField!: string;

  @Prop({ type: Number, default: () => 0 })
  startPageIndex!: number;

  @Prop({ type: String, default: () => "" })
  moduleName!: string;

  @Prop({ type: Boolean, default: () => true })
  multipleSelect!: boolean;

  @Prop({ type: Boolean, default: () => false })
  columnConfigable!: boolean;

  @Prop({ type: String, default: () => "暂无数据" })
  emptyText!: string;

  @Inject("getModuleName")
  getMainModuleName!: () => string;

  _isSearching!: boolean | undefined;

  currentDataRow: any = null;

  created() {
    if (this.defaultPageSize !== this.pageSize) {
      this.pageSize = this.defaultPageSize;
    }
    this.$nextTick(() => this.autoLoad && this.doSearchData());
  }

  get iPageSize() {
    return this.pageSize;
  }

  set iPageSize(value) {
    this.$emit("update:pageSize", value);
  }

  get columnData() {
    return (JSON.parse(JSON.stringify(this.columns)) as Array<
      TableColumnOption
    >).filter(col => col.hidden !== true);
  }

  get hiddenColumns() {
    const cols: Array<TableColumnOption> = this.$store.state[
      this.getModuleName()
    ].columns;
    if (!cols) {
      return [];
    }
    return JSON.parse(JSON.stringify(cols.filter(col => col.hidden === true)));
  }

  get columns(): Array<TableColumnOption> {
    const cols: Array<TableColumnOption> = this.getStoreData("columns", []);
    if (!cols.length) {
      cols.push({ prop: "--unknown--", display: "", sortable: false });
    }
    return cols;
  }

  get tableData(): Array<TableColumnOption> {
    return this.getStoreData("data", []);
  }

  get recordCount() {
    return this.getStoreData("size", 0);
  }

  getRowNumber(rowIndex: number) {
    return (this.pageIndex - 1) * this.pageSize + (rowIndex + 1);
  }

  getModuleName() {
    return isEmptyOrWhiteSpace(this.moduleName)
      ? this.getMainModuleName()
      : this.moduleName;
  }

  execRowNumberColumnWidth() {
    const currentMax = (this.pageIndex * this.pageSize).toString();
    const baseWidth = 40;
    const seed = 6;
    if (currentMax.length - 2 > 0) {
      return baseWidth + (currentMax.length - 2) * seed;
    }
    return 40;
  }

  getStoreData(name: string, defaultValue: any = []) {
    const module = this.getModuleName();
    if (isEmpty(module)) {
      return defaultValue;
    }
    const sub = this.$store.state[module];
    if (isEmpty(sub)) {
      return defaultValue;
    }
    return sub[name];
  }

  isColFormatter(formatter: any) {
    return (
      formatter &&
      Object.prototype.toString.call(formatter) === "[object Function]"
    );
  }

  setPageIndex(index: number) {
    if (!index || index <= 0) {
      index = 1;
    }
    this.pageIndex = index;
  }

  @Watch("pageIndex")
  doSearchData() {
    if (this._isSearching === true) {
      return;
    }
    this._isSearching = true;
    let queryParams: any = {};
    if (!isEmpty(this.queryParams) && !isEmpty(this.queryParams)) {
      queryParams = JSON.parse(this.queryParams);
    }
    const privKeys = Object.keys(queryParams).filter(
      name => name.substr(0) === "_"
    );
    if (privKeys && privKeys.length) {
      privKeys.forEach(key => delete queryParams[key]);
    }
    queryParams.pageSize = this.pageSize;

    queryParams.page =
      this.startPageIndex === 0 ? this.pageIndex - 1 : this.pageIndex;
    if (!isEmpty(this.orderProp)) {
      queryParams.orderBy = this.orderProp;
    }
    const payload = {
      api: this.dataApi,
      params: queryParams,
      method: queryParams.requestMethod
    };
    this.$store
      .dispatch(`${this.getModuleName()}/query`, payload)
      .then((...args: any) => {
        const wrapper = document.querySelector(".el-table__body-wrapper");
        if (wrapper) {
          wrapper.scrollTop = 0;
        }
        this._isSearching = false;
      })
      .catch((resp: any) => {
        showError(resp.message);
        this._isSearching = false;
      });
  }

  onDataCommand(command: string, row: any, event: any) {
    this.$emit("data-command", command, row, event);
    this.setCurrentDataRow(row);
  }

  @Watch("tableData")
  onDataLoaded() {
    const table = this.$refs.innertable as any;
    if (this.tableData && this.tableData.length) {
      this.$nextTick(() => this.$emit("data-loaded"));
      // 恢复选中状态
      this.tableData
        .filter((data: any) => this.selectedRows.has(data[this.rowIdField]))
        .forEach(row =>
          this.$nextTick(() => table.toggleRowSelection(row, true))
        );
    }
    this.$nextTick(() => table.doLayout());
  }

  @Watch("queryParams")
  @Watch("pageSize")
  @Watch("orderProp")
  onPageSizeChange() {
    if (this.pageIndex === 1) {
      this.doSearchData();
    } else {
      this.pageIndex = 1;
    }
  }

  @Watch("selectedCount")
  onSelectionChange() {
    const values: Array<string> = [];
    this.selectedRows.forEach(row => values.push(row));
    this.$emit("selection-changed", values);
  }

  @Watch("selectedOneRow")
  onRadioValueChange() {
    const moduleState = this.$store.state[`${this.getModuleName()}`];
    if (moduleState && moduleState.data) {
      const foundItems = (moduleState.data as Array<any>).filter(
        item => item.id === this.selectedOneRow
      );
      if (foundItems && foundItems.length) {
        this.setCurrentDataRow(foundItems[0]);
      }
    }
  }

  onSortChange({ prop, order }: any) {
    if (isEmpty(prop) || isEmpty(order)) {
      this.orderProp = "";
    } else {
      this.orderProp = `${prop} ${order.replace("ending", "")}`;
    }
  }

  onRowClick() {
    this.$emit("row-click", ...Array.from(arguments));
  }

  onCellClick() {
    this.$emit("cell-click", ...Array.from(arguments));
  }

  onRowSelected(selectedRows: Array<any>, current: any) {
    if (selectedRows.indexOf(current) > -1) {
      this.selectedRows.set(
        current[this.rowIdField],
        JSON.parse(JSON.stringify(current))
      );
    } else {
      this.selectedRows.delete(current[this.rowIdField]);
    }
    this.selectedCount = this.selectedRows.size;
    this.$emit("row-selected", ...Array.from(arguments));
  }

  onSelectAll(rows: Array<any>) {
    if (rows && rows.length) {
      rows.forEach(row =>
        this.selectedRows.set(
          row[this.rowIdField],
          JSON.parse(JSON.stringify(row))
        )
      );
    } else {
      (this.tableData || []).forEach((data: any) =>
        this.selectedRows.delete(data[this.rowIdField])
      );
    }
    this.selectedCount = this.selectedRows.size;
  }

  getSelectedDataRows() {
    const values: Array<any> = [];
    this.selectedRows.forEach(val => values.push(val));
    return values;
  }

  getSelectedRows() {
    if (!this.multipleSelect) {
      return isEmptyOrWhiteSpace(this.selectedOneRow)
        ? []
        : [this.selectedOneRow];
    }
    const values: Array<any> = [];
    this.selectedRows.forEach((val, key) => values.push(key));
    return values;
  }

  clearSelection() {
    this.selectedRows.clear();
    this.selectedOneRow = "";
    this.selectedCount = 0;
    this.currentDataRow = null;
  }

  openColumnConfig() {
    this.showColumnConfigDialog = true;
  }

  toggleSearchPanel(status: boolean) {
    this.$emit("update:showSearchPanel", status);
  }

  onColumnResize(newWidth: number, oldWidth: number, column: TableColumn) {
    this.$emit("column-resize", newWidth, column);
  }

  saveColumnConfig() {
    const selector = this.$refs.colSelector as any;
    if (!selector) {
      return;
    }
    const listData = selector.getListData() || [];
    const selectedItems = selector.getSelectedItems() || [];

    const realColumns: Array<any> = [].concat(
      (this.$refs.innertable as any).columns
    );

    [].concat(listData, selectedItems).forEach((column: any) => {
      const index = realColumns.findIndex(rl => rl.property === column.prop);
      if (index === -1) {
        return;
      }
      const realCol = realColumns[index];
      column.width = realCol.width;
      realColumns.splice(index, 1);
    });

    this.$emit("columnconfig-completed", {
      data: listData,
      hidden: selectedItems
    });
    this.showColumnConfigDialog = false;
  }

  setCurrentDataRow(row: any) {
    if (!row) {
      this.currentDataRow = null;
      return;
    }
    const table = this.$refs.innertable as any;
    table.setCurrentRow(row);
    this.currentDataRow = row;
  }

  getCurrentDataRow() {
    return this.currentDataRow;
  }

  isLastPage(): boolean {
    return Math.ceil(this.recordCount / this.iPageSize) === this.pageIndex;
  }

  isFirstPage(): boolean {
    return this.pageIndex === 1;
  }

  @Watch("queryParams")
  onQueryCondChange() {
    this.clearSelection();
  }
}
