import { Module } from "vuex";
import { Fetcher } from "@/utils/Request";

import { API_LOGIN, API_LOGOUT, API_USER_ME } from "@/configs/Apis";

const module: Module<{ user: any }, any> = {
  namespaced: true,
  state: {
    user: null
  },
  mutations: {
    setUser(state: any, user: any) {
      state.user = user;
    }
  },

  getters: {
    user(state: any) {
      return JSON.parse(JSON.stringify(state.user)) || {};
    }
  },

  actions: {
    getUser({ commit }) {
      const api = `${API_USER_ME}?_=${new Date().valueOf()}`;
      return Fetcher.queryData(api, {}, "GET").then(userData => {
        commit("setUser", userData);
      }).catch(() => "");
    },
    logout({ commit }) {
      const api = `${API_LOGOUT}?_=${new Date().valueOf()}`;
      return Fetcher.queryData(api, {}, "DELETE").then(() => commit("setUser", null));
    },
    login({ dispatch }, params) {
      return Fetcher.queryData(API_LOGIN, params).then(() => dispatch("getUser"));
    }
  }
};

export default module;
