import { API_LIST_LAYOUT } from "@/configs/Apis";
import { isEmptyOrWhiteSpace } from "@/utils/Common";
import { Fetcher as Ajax } from "@/utils/Request";
import { Module } from "vuex";

declare interface ListState {
  columns: Array<TableColumnOption>;
  data: Array<any>;
  size: number;
}

const module: Module<ListState, any> = {
  namespaced: true,
  state: () => ({
    data: [],
    columns: [],
    size: 0
  }),
  mutations: {
    data(state, data) {
      state.data = (data || []);
    },
    columns(state, data) {
      state.columns = (data || []);
    },
    size(state, data) {
      state.size = (data || 0);
    }
  },
  actions: {
    query({ commit }, { api, params, method, autoCommit = true }) {
      return Ajax.queryData(api, params, method).then(({ count = 0, data = [] }) => {
        if (autoCommit) {
          commit("data", data);
          commit("size", count);
        }
        return { count, data };
      });
    },
    get({ commit }, { api, params }) {
      return Ajax.queryData(api, params, "GET");
    },
    post({ commit }, { api, params }) {
      return Ajax.saveData(api, params, "POST");
    },
    put({ commit }, { api, params }) {
      return Ajax.saveData(api, params, "PUT");
    },
    delete({ commit }, { api, params }) {
      return Ajax.saveData(api, params, "DELETE");
    },
    setColumns({ commit }, columns) {
      return Promise.resolve(commit("columns", columns));
    },
    clearData({ commit }) {
      commit("data", []);
      commit("columns", []);
      commit("size", 0);
    },
    getListLayout({ dispatch }, { listName }) {
      const cacheKey = `LIST_LAYOUT_AEMW_${listName}`;
      let layout;
      if ((layout = window.sessionStorage.getItem(cacheKey))) {
        return layout;
      }
      // 判断缓存
      return dispatch("get", { api: `${API_LIST_LAYOUT}/${listName}`, params: {} }).then(layout => {
        if (!isEmptyOrWhiteSpace(layout)) {
          window.sessionStorage.setItem(cacheKey, layout);
        }
        return layout;
      });
    },
    saveListLayout({ dispatch }, { listName, layout }) {
      // 判断缓存
      const cacheKey = `LIST_LAYOUT_AEMW_${listName}`;
      return dispatch("put", { api: `${API_LIST_LAYOUT}/${listName}`, params: { listName, layout } }).then(() => window.sessionStorage.removeItem(cacheKey));
    },
    setColumnWidth({ dispatch }, { listName, column, newWidth }) {
      const cacheKey = `LIST_LAYOUT_AEMW_${listName}`;
      const layout = window.sessionStorage.getItem(cacheKey);
      if (isEmptyOrWhiteSpace(layout)) {
        return;
      }
      const columnLayout: Array<TableColumnOption> = JSON.parse(layout || "");
      const found = columnLayout.find(col => col.prop === column);
      if (found) {
        found.width = newWidth;
      }
      window.sessionStorage.setItem(cacheKey, JSON.stringify(columnLayout));
    }
  }
};

export default module;
