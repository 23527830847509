import { Module } from "vuex";
import { isEmptyOrWhiteSpace } from "@/utils/Common";

declare interface MenuItem {
  name: string;
  path: string;
  permissions: Array<string>;
  order: number;
  icon?: any;
  indexPage?: Array<string>;
}

const storeOption: Module<{ menus: Array<MenuItem> | null }, any> = {
  namespaced: true,
  state: {
    menus: [
      {
        name: "待办时限",
        path: "activities",
        permissions: [],
        order: 0,
        icon: require("../../../assets/images/menus/activity.png")
      },
      {
        name: "专利管理",
        path: "patents",
        permissions: ["PATENT"],
        order: 100,
        icon: require("../../../assets/images/menus/monitor.png")
      },
      {
        name: "客户管理",
        path: "users",
        permissions: ["CUSTOMER"],
        order: 200,
        icon: require("../../../assets/images/menus/user.png")
      },
      {
        name: "系统设置",
        path: "settings",
        permissions: ["SETTING"],
        order: 999,
        icon: require("../../../assets/images/menus/settings.png")
      }
    ]
  },
  getters: {
    menus: (state: any) => (user: any) => {
      const menus = state.menus || [];
      const findMenus = menus.filter((m: MenuItem) => {
        if (user.userType === "ADMIN") {
          return true;
        }
        if (!m.permissions || m.permissions.length === 0) {
          return true;
        }
        if (!user.permission || isEmptyOrWhiteSpace(user.permission.permissions)) {
          return false;
        }
        const permissions = JSON.parse(user.permission.permissions) || [];
        for (let idx = 0; idx < m.permissions.length; idx++) {
          if (permissions.indexOf(m.permissions[idx]) >= 0) {
            return true;
          }
        }
        return false;
      }) || [];
      return JSON.parse(JSON.stringify(findMenus)).sort((m1: MenuItem, m2: MenuItem) => m1.order - m2.order);
    }
  }
};

export default storeOption;
