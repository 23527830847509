import DFloatContainer from "@/components/DFloatContainer.vue";
import DContainerLayout from "@/views/base/DContainerLayout.vue";
import DList from "@/views/base/DList.vue";
import {
  Alert,
  Autocomplete,
  Aside, Button, Card, Carousel, CarouselItem, Cascader,
  CascaderPanel, Checkbox,
  CheckboxGroup, Col, Collapse,
  CollapseItem, Container, DatePicker, Dialog, Divider, Empty, Form,
  FormItem, Header, Image, Input, InputNumber, Link, Loading, Main, Menu,
  MenuItem,
  MenuItemGroup, Option,
  Pagination, Popover, Progress, Radio,
  RadioGroup, Row, Select, Step, Steps, Submenu, Switch, Table,
  TableColumn,
  TabPane,
  Timeline, TimelineItem,
  Tabs, Tag, Tooltip, Tree, Upload
} from "element-ui";
import Vue from "vue";

export default function () {
  Vue.component(Alert.name, Alert);
  Vue.component(Autocomplete.name, Autocomplete);
  Vue.component(Container.name, Container);
  Vue.component(Header.name, Header);
  Vue.component(Aside.name, Aside);
  Vue.component(Main.name, Main);
  Vue.component(Input.name, Input);
  Vue.component(Button.name, Button);
  Vue.component(Menu.name, Menu);
  Vue.component(MenuItem.name, MenuItem);
  Vue.component(MenuItemGroup.name, MenuItemGroup);
  Vue.component(Submenu.name, Submenu);
  Vue.component(Table.name, Table);
  Vue.component(TableColumn.name, TableColumn);
  Vue.component(TabPane.name, TabPane);
  Vue.component(Tabs.name, Tabs);
  Vue.component(Select.name, Select);
  Vue.component(Option.name, Option);
  Vue.component(Pagination.name, Pagination);
  Vue.component(DatePicker.name, DatePicker);
  Vue.component(Form.name, Form);
  Vue.component(FormItem.name, FormItem);
  Vue.component(Row.name, Row);
  Vue.component(Col.name, Col);
  Vue.component(Checkbox.name, Checkbox);
  Vue.component(CheckboxGroup.name, CheckboxGroup);
  Vue.component(Tree.name, Tree);
  Vue.component(Radio.name, Radio);
  Vue.component(RadioGroup.name, RadioGroup);
  Vue.component(InputNumber.name, InputNumber);
  Vue.component(Image.name, Image);
  Vue.component(Popover.name, Popover);
  Vue.component(Progress.name, Progress);
  Vue.component(Tooltip.name, Tooltip);
  Vue.component(Card.name, Card);
  Vue.component(Dialog.name, Dialog);
  Vue.component(Switch.name, Switch);
  Vue.component(Upload.name, Upload);
  Vue.component(Collapse.name, Collapse);
  Vue.component(CollapseItem.name, CollapseItem);
  Vue.component(Tag.name, Tag);
  Vue.component(Cascader.name, Cascader);
  Vue.component(CascaderPanel.name, CascaderPanel);
  Vue.component(Link.name, Link);

  Vue.component(Timeline.name, Timeline);
  Vue.component(TimelineItem.name, TimelineItem);

  Vue.component(Carousel.name, Carousel);
  Vue.component(CarouselItem.name, CarouselItem);

  Vue.component(Step.name, Step);
  Vue.component(Steps.name, Steps);

  Vue.component(Empty.name, Empty);

  Vue.component("DFloatContainer", DFloatContainer);
  Vue.component("DContainerLayout", DContainerLayout);
  Vue.component("DList", DList);
  Vue.component(Divider.name, Divider);

  Vue.use(Loading);
}
