




















import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import storeMap from "@/stores/maps";
import commonMudule from "@/stores/modules/views/common";
import { isEmpty } from "@/utils/Common";

@Component
export default class DContainerLayout extends Vue {
  queryData: any = {};

  @Prop({ type: String, default: () => "" })
  title!: string;

  @Prop({ type: String, default: () => "" })
  moduleName!: string;

  @Prop({ type: String, default: () => "" })
  iconUrl!: string;

  onClick() {
    this.$emit("click");
  }

  @Provide()
  getModuleName() {
    return this.moduleName;
  }

  created() {
    if (isEmpty(this.moduleName)) {
      return;
    }
    this.$nextTick(() => this.registerModule());
  }

  beforeDestroy() {
    if (isEmpty(this.moduleName)) {
      return;
    }
    this.$store.unregisterModule(this.moduleName);
  }

  registerModule() {
    let data = null;
    if (Object.prototype.hasOwnProperty.call(storeMap, this.moduleName)) {
      data = storeMap[this.moduleName];
    } else {
      data = commonMudule;
    }
    this.$store.registerModule(this.moduleName, data || {});
    this.$emit("module-registered");
  }
}
