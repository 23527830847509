























import { Vue, Component, Prop, Watch } from "vue-property-decorator";

function closeDialogOnBodyClick() {
  if (!floaterManager.length) {
    return;
  }
  floaterManager.forEach(fl => {
    if (fl.visible) {
      fl.closeDialog();
    }
  });
}

const floaterManager: Array<DFloatContainer> = [];
let counter = 0;

@Component
export default class DFloatContainer extends Vue {
  @Prop({ type: Boolean, default: () => false })
  visible!: boolean;

  @Prop({ type: String, default: () => "无标题" })
  title!: string;

  @Prop({ type: Boolean, default: false })
  closeOnClickBody!: boolean;

  closeDialog() {
    this.$emit("update:visible", false);
    this.$emit("close");
  }

  mounted() {
    document.body.appendChild(this.$el);
    if (this.closeOnClickBody) {
      this.addCloseEventListener();
    }
    floaterManager.push(this);
  }

  addCloseEventListener() {
    if (counter > 0) {
      return;
    }
    document.body.addEventListener("click", closeDialogOnBodyClick);
    counter += 1;
  }

  removeCloseEventListener() {
    document.body.removeEventListener("click", closeDialogOnBodyClick);
    counter -= 1;
  }

  beforeDestroy() {
    const parent = this.$el.parentNode;
    if (parent) {
      parent.removeChild(this.$el);
    }
    this.removeCloseEventListener();
    const index = floaterManager.indexOf(this);
    if (index > -1) {
      floaterManager.splice(index, 1);
    }
  }

  @Watch("closeOnClickBody")
  onCloseOnClickBodyChange(newValue: boolean) {
    this.removeCloseEventListener();
    if (newValue) {
      this.addCloseEventListener();
    }
  }
}
