export const MSG_INVALID_PAGE = "数据验证错误，请查看页面错误信息";
export const MSG_VALIDATE_CODE = "验证成功";
export const MSG_SENDAUTHCODE = "验证码发送成功，请前往邮箱进行查看";
export const MSG_CHANGEPASSWORD_SUCCESS = "密码修改成功";

export const MSG_MODIFY_SUCCESS = "修改成功";
export const MSG_ADD_SUCCESS = "添加成功";
export const MSG_DELETE_SUCCESS = "删除成功";

export const MSG_EXPORT_FILE_NOTFOUND = "没有找到文件路径，请稍后再试";

export const MSG_001 = "请至少选择一条数据";

export const MSG_SAVE_SUCCESS = "保存成功";

