import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import { Module } from "vuex";
import baseModule from "./common";
const newModule: Module<any, any> = merge(cloneDeep(baseModule), {
  actions: {

  }
});
export default newModule;
