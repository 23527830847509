














































import { Vue, Component } from "vue-property-decorator";
import { Fetcher as Ajax } from "@/utils/Request";
import { API_OPENED_SYSTEM_INFO } from "@/configs/Apis";
@Component
export default class SystemInfo extends Vue {
  systemInfoItem: any = {};
  visible = false;
  open() {
    this.visible = true;
    Ajax.queryData(API_OPENED_SYSTEM_INFO, {}, "GET")
      .then(data => {
        this.systemInfoItem = JSON.parse(data.license);
        this.systemInfoItem.orgName = data.name || "";
        this.systemInfoItem.prodName = process.env.VUE_APP_TITLE;
      })
      .catch(() => "");
  }

  onClose() {
    this.systemInfoItem = {};
    this.visible = false;
  }

  close() {
    this.onClose();
  }
}
