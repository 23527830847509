import { API_ACTIVITY, API_ACTIVITY_TREE, API_ACTIVITY_ASSIGN } from "@/configs/Apis";
import { Fetcher as Ajax } from "@/utils/Request";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import { Module } from "vuex";
import baseModule from "./common";

const newModule: Module<any, any> = merge(cloneDeep(baseModule), {
  actions: {
    getActivityTree({ commit }: any) {
      return Ajax.queryData(API_ACTIVITY_TREE, {}, "GET", false)
        .then(data => {
          commit("setActivityTree", data);
        })
        .catch(() => {
          commit("setActivityTree", [
            {
              title: "全部",
              count: 0,
              icon: "all-data",
              activityFilter: {}
            }
          ]);
        });
    },
    addActivity(store: any, payload: any) {
      return Ajax.saveData(API_ACTIVITY, payload, "POST");
    },
    updateActivity(store: any, payload: any) {
      return Ajax.saveData(API_ACTIVITY + "/" + payload.id, payload, "PUT");
    },
    deleteActivity(store: any, filter: any) {
      return Ajax.saveData(API_ACTIVITY, filter, "DELETE");
    },
    assignActivity(store: any, filter: any) {
      return Ajax.saveData(API_ACTIVITY_ASSIGN, filter, "POST");
    }
  },
  mutations: {
    setActivityTree(state: any, data: any) {
      state.activityTree = data;
    }
  }
});

const oldState = newModule.state as () => Object;

newModule.state = () => ({ ...oldState(), activityTree: null });

export default newModule;
