






























import { Vue, Component } from "vue-property-decorator";
@Component
export default class Systemhelo extends Vue {
  visible = false;
  open() {
    this.visible = true;
  }

  onClose() {
    this.visible = false;
  }

  close() {
    this.onClose();
  }
}
