import { API_REMIND, API_REMIND_READ, API_REMIND_TREE } from "@/configs/Apis";
import { Fetcher as Ajax } from "@/utils/Request";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import { Module } from "vuex";
import baseModule from "./common";

const newModule: Module<any, any> = merge(cloneDeep(baseModule), {
  actions: {
    getMessageTree({ commit }: any) {
      return Ajax.queryData(API_REMIND_TREE, {}, "GET", false).then((data) => {
        commit("setMessageTree", data[0].children);
      }).catch(() => {
        commit("setMessageTree", []);
      });
    },
    readMessage(store: any, remindMessageFilter: any) {
      return Ajax.saveData(API_REMIND_READ, remindMessageFilter, "PUT");
    },
    deleteMessage(store: any, remindMessageFilter: any) {
      return Ajax.saveData(API_REMIND, remindMessageFilter, "DELETE");
    }
  },
  mutations: {
    setMessageTree(state: any, data: any) {
      state.messageTree = data;
    }
  }
});

const oldState = newModule.state as () => Object;

newModule.state = () => ({ ...oldState(), messageTree: null });

export default newModule;
