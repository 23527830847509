import axios, { AxiosRequestConfig } from "axios";
import startsWith from "lodash/startsWith";
import objectAssign from "lodash/assign";

import { Loading } from "element-ui";

import { ElLoadingComponent } from "element-ui/types/loading";

import { SERVER_HOST } from "@/configs/AppParams";
import { isEmpty } from "@/utils/Common";

let loadingInstance: ElLoadingComponent | null;
let ajaxRequestCounter = 0;

class Request {
  static async saveData(api: string, pars: any, method = "POST"): Promise<any> {
    const savedUrl = Request.formatApiUrl(api, {});
    const postedParams = pars || {};
    return await Request.request(savedUrl, postedParams, method);
  }

  static async query(api: string, pars: any, method = "POST") {
    const savedUrl = Request.formatApiUrl(api, {});
    const postedParams = pars || {};
    return await Request.request(savedUrl, postedParams, method);
  }

  private static async request(url: string, pars: { [key: string]: any } | null, method = "POST") {
    try {
      const resp = await axios({
        method: (method || "POST").toLowerCase(),
        url: url,
        data: pars,
        timeout: 1000000,
        headers: objectAssign({ "Content-Type": "application/json" }, {})
      } as AxiosRequestConfig);
      return resp.status === 200 ? resp.data : null;
    } catch (e: any) {
      if (e.response && e.response.data) {
        throw new Error(e.response.data.message);
      } else {
        throw e;
      }
    }
  }

  static formatApiUrl(api: string, params: any) {
    if (startsWith(api, "/")) {
      api = api.substring(1);
    }
    let p;
    if (typeof params === "string") {
      p = params;
    } else if (typeof params === "object" && params !== null) {
      const arr = [];
      for (const prop in params) {
        if (!Object.prototype.hasOwnProperty.call(params, prop)) {
          continue;
        }
        arr.push(`${prop}=${(params as any)[prop]}`);
      }
      p = arr.join("&");
    }
    return `${SERVER_HOST}/${api}${p ? "?" + p : ""}`;
  }
}

export class Fetcher extends Request {
  static async saveData(api: string, params: any, method = "POST") {
    try {
      this.showLoading();
      const data = await super.saveData(api, params, method);
      this.hideLoading();
      return data;
    } catch (e) {
      this.hideLoading();
      throw e;
    }
  }

  static async queryData(api: string, params: any, method = "POST", showLoading = true) {
    try {
      if (isEmpty(params)) {
        params = {};
      }
      if (showLoading) {
        this.showLoading();
      }
      if (method === "GET") {
        const queryStrs = params;
        const querys: Array<string> = [];
        Object.keys(queryStrs).forEach(prop => querys.push(`${prop}=${queryStrs[prop]}`));
        if (querys.length > 0) {
          if (api.indexOf("?") > -1) {
            api += "&" + querys.join("&");
          } else {
            api += "?" + querys.join("&");
          }
        }
      }
      const data = await super.query(api, params, method);
      if (showLoading) {
        this.hideLoading();
      }
      return data;
    } catch (e) {
      if (showLoading) {
        this.hideLoading();
      }
      throw e;
    }
  }

  static async queryList(params: any) {
    const newParams = <any>objectAssign({}, params);
    newParams.condObj = JSON.stringify(newParams.condObj);
    return this.queryData("/api/base/BaseList", newParams);
  }

  private static hideLoading() {
    ajaxRequestCounter -= 1;
    if (loadingInstance && ajaxRequestCounter === 0) {
      loadingInstance.close();
      loadingInstance = null;
    }
  }

  private static showLoading() {
    ajaxRequestCounter += 1;
    if (!loadingInstance) {
      loadingInstance = Loading.service({ lock: true, customClass: "cus-loading" });
    }
  }
}
