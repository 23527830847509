import { Message, MessageBox } from "element-ui";
import lodashIsEmpty from "lodash/isEmpty";
import trim from "lodash/trim";

export function isEmpty(value: any): boolean {
  if (value === null || value === undefined) {
    return true;
  }

  if (Object.prototype.toString.call(value) === "[object Number]") {
    return false;
  }

  return lodashIsEmpty(value);
}

export function isEmptyOrWhiteSpace(value: any): boolean {
  return isEmpty(trim(value));
}

export function showError(message: string) {
  return MessageBox({ title: "错误", message: message, type: "error", customClass: "dudu-confirm" });
}

export function showWarning(message: string) {
  return Message({ message: message, type: "warning", dangerouslyUseHTMLString: true });
}

export function showSuccess(message: string) {
  return Message({ message: message, type: "success" });
}

export function showSuccessLong(message: string, delay = 30) {
  return Message({ message: message, type: "success", duration: delay * 1000, showClose: true, dangerouslyUseHTMLString: true });
}

export function showImportantMessage(message: string) {
  return MessageBox({ title: "重要提示", message: `<font color="#F56C6C">${message}</font>`, type: "success", dangerouslyUseHTMLString: true, customClass: "dudu-confirm" });
}

export function showConfirm(message: string, cls?: string) {
  return MessageBox.confirm(message, {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
    // center: true,
    dangerouslyUseHTMLString: true,
    customClass: "dudu-confirm"
  });
}

export function getEnumMap(data: Array<any>) {
  const map = new Map();
  const items = data;
  if (items && items.length) {
    items.forEach((data: any) => map.set(data.enumValue, data.label));
  }
  return map;
}

export function mergeColumns(columns: Nullable<Array<TableColumnOption>>, defaultColumns: Array<TableColumnOption>) {
  if (columns === null || columns === undefined || columns.length === 0) {
    return defaultColumns;
  }
  const map = new Map<string, { marked: number, column: TableColumnOption }>();
  defaultColumns.forEach(col => map.set(col.prop, { marked: 0, column: col }));
  const newColumns: Array<TableColumnOption> = [];
  columns.forEach(col => {
    if (!map.has(col.prop)) {
      return;
    }
    const defaultItem = map.get(col.prop);
    if (defaultItem === null || defaultItem === undefined) {
      return;
    }
    defaultItem.marked = 1;
    col.display = defaultItem.column.display;
    newColumns.push(col);
  });
  map.forEach(col => {
    // 没有标记的字段是新添加的字段
    if (col.marked === 0) {
      newColumns.push(col.column);
    }
  });
  return newColumns;
}

export function hasPermission(user: any, checkedPerm: string) {
  if (user.userType === "ADMIN") {
    return true;
  }

  if (isEmptyOrWhiteSpace(checkedPerm)) {
    return true;
  }

  if (!user.permission || isEmptyOrWhiteSpace(user.permission.permissions)) {
    return false;
  }
  
  const permissions = JSON.parse(user.permission.permissions) || [];
  return permissions.indexOf(checkedPerm) >= 0;
}
