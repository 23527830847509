export const API_LOGIN = "/auth/login";
export const API_LOGOUT = "/users/logout";
// export const API_REFRESH_TOKEN = "/auth/refresh";

export const API_AUTH_SEND_AUTH_CODE = "/auth/send-auth-code";
export const API_AUTH_CHANGE_PASSWORD = "/auth/change-password";
// export const API_AUTH_WEIXIN_BINDING_CHECK = "/auth/weixin-binding-check";
// export const API_AUTH_WEIXIN_BINDING = "/auth/weixin-binding";

export const API_CLIENT_CRASH = "/clients/crash";
export const API_ENUMS = "/enums";

export const API_USER = "/users";
export const API_USER_ME = "/users/me";
export const API_USER_CHANGE_PASSWORD = "/users/change-password";
export const API_USER_LIST = "/users/list";
export const API_USER_DISABLE = "/users/disable";
export const API_USER_ENABLE = "/users/enable";
export const API_USER_TREE = "/users/tree";
// export const API_USER_WEIXIN_BINDING = "/user/weixin-binding";
// export const API_USER_PUSH_RULE = "/users/push-rule";

export const API_PATENT = "/patents";
export const API_PATENT_LIST = "/patents/list";
export const API_PATENT_LIST_BY_OPEN_API = "/patents/list-by-open-api";
export const API_PATENT_ADD_BY_OPEN_API = "/patents/add-by-open-api";
export const API_PATENT_ADD_BY_OPEN_API_TASK = "/patents/add-by-open-api-task";
export const API_PATENT_TREE = "/patents/tree";
export const API_PATENT_USER = "/patents/users";
export const API_PATENT_EXTRA_FEE = "/patents/extra-fees";
export const API_PATENT_FILE = "/patents/files";
export const API_PATENT_UNFOLLOW = "/patents/unfollow";
export const API_PATENT_FOLLOW = "/patents/follow";
// export const API_PATENT_STATUS_STAT = "/patents/status-stat";
// export const API_PATENT_MY_LIST = "/patents/my-list";
export const API_PATENT_FILTER_OPTIONS = "/patents/filter-options";
export const API_PATENT_LEGAL_STATUS = "/patents/legal-status";
export const API_PATENT_LOGIC_DELETE = "/patents/logic";
export const API_PATENT_RECOVER = "/patents/recover";

export const API_LIST_LAYOUT = "/list-layouts";

export const API_PATENT_FILED = "/patent-fields";

export const API_IMPORT_PREVIEW = "/imports/preview";
export const API_IMPORT_MAPPING = "/imports/mapping";
export const API_IMPORT_TASK = "/imports/tasks";

// export const API_EXPORT_TASK = "/exports/tasks";
export const API_EXPORT_TASK_SYNC = "/exports/tasks/sync";

export const API_SETTING_ORG_INFO = "/settings/org-info";
export const API_SETTING_WEIXIN_API = "/settings/weixin-api";
export const API_SETTING_PUSH_RULE = "/settings/push-rule";

export const API_REMIND = "/reminds";
export const API_REMIND_TREE = "/reminds/tree";
export const API_REMIND_LIST = "/reminds/list";
export const API_REMIND_MY_LIST = "/reminds/my-list";
export const API_REMIND_READ = "/reminds/read";
export const API_REMIND_UN_READ_COUNTS = "/reminds/un-read-counts";
export const API_REMIND_PUSH_LIST = "/reminds/push-list";

export const API_SYSTEM_LOG_LIST = "/system-logs/list";

export const API_UPLOAD_PATENT_FILE = "/uploads/patent-file";
export const API_UPLOAD_PATENT_EXCEL = "/uploads/patent-excel";
export const API_UPLOAD_USER_EXCEL = "/uploads/user-excel";
export const API_UPLOAD_ORG_LOGO = "/uploads/org-logo";
export const API_UPLOAD_ATTACHMENT = "/uploads/attachment";

export const API_OPENED_SYSTEM_INFO = "/opened/system-info";
export const API_OPENED_WEB_NOTICE = "/opened/web-notice";
export const API_OPENED_ORG_INFO = "/opened/org-info";

export const API_ACTIVITY = "/activities";
export const API_ACTIVITY_LIST = "/activities/list";
export const API_ACTIVITY_TREE = "/activities/tree";
export const API_ACTIVITY_ASSIGN = "/activities/assign";
export const API_ACTIVITY_DEADLINE = "/activities/deadline";
export const API_ACTIVITY_ATTACHMENT = "/activities/attachments";
export const API_ACTIVITY_COMMENT = "/activities/comments";

export const API_APPLICANT_LIST = "/applicants/list";
export const API_INVENTOR_LIST = "/inventors/list";

export const API_PERMISSION_ROLE = "/permissions/roles";