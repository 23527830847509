import Vue from "vue";
import Vuex from "vuex";
import enums from "./modules/shared/enums";
import menu from "./modules/shared/menu";
import patentsync from "./modules/shared/patentsync";
import user from "./modules/shared/user";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    menu,
    enums,
    patentsync
  }
});
