








































import { Vue, Component } from "vue-property-decorator";
import { Fetcher as Ajax } from "@/utils/Request";
import { API_USER_CHANGE_PASSWORD } from "@/configs/Apis";
import { showError, showSuccess, showWarning } from "@/utils/Common";
import { MSG_INVALID_PAGE, MSG_MODIFY_SUCCESS } from "@/configs/Consts";
@Component
export default class ChangePassword extends Vue {
  passwordDataItem: any = {};
  visible = false;
  open() {
    this.visible = true;
  }

  onClose() {
    this.passwordDataItem = {};
    this.visible = false;
  }

  get validateRules() {
    return {
      oldPassword: {
        required: true,
        message: "请输入原密码",
        trigger: "blur"
      },
      newPassword: [
        {
          required: true,
          message: "请输入新密码",
          trigger: "blur"
        },
        {
          validator: (
            rule: any,
            value: string,
            callback: (error?: Error) => void
          ) => {
            if (value === this.passwordDataItem.oldPassword) {
              callback(new Error("新密码不能与原密码相同"));
            } else {
              callback();
            }
          },
          trigger: "blur"
        }
      ],
      confirmNewPassowrd: [
        {
          required: true,
          message: "请再一次输入密码",
          trigger: "blur"
        },
        {
          validator: (
            rule: any,
            value: string,
            callback: (error?: Error) => void
          ) => {
            if (value !== this.passwordDataItem.newPassword) {
              callback(new Error("两次输入的密码不一致"));
            } else {
              callback();
            }
          },
          trigger: "blur"
        }
      ]
    };
  }

  modifyPassword() {
    const form = this.$refs.passwordForm as any;
    form.validate((isValid: boolean) => {
      if (isValid) {
        const postedParams = {
          password: this.passwordDataItem.newPassword,
          oldPassword: this.passwordDataItem.oldPassword
        };
        Ajax.saveData(API_USER_CHANGE_PASSWORD, postedParams, "PUT")
          .then(() => showSuccess(MSG_MODIFY_SUCCESS))
          .then(() => {
            this.onClose();
          })
          .catch(({ message }) => showError(message));
      } else {
        showWarning(MSG_INVALID_PAGE);
      }
    });
  }
}
