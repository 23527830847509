



























































































import { Component, Prop, Vue } from "vue-property-decorator";
import VueDraggable from "vuedraggable";

@Component({
  components: { VueDraggable }
})
export default class DItemSelector extends Vue {
  @Prop({ type: Array, default: () => [] })
  source!: Array<any>;

  @Prop({ type: Array, default: () => [] })
  selectedItems!: Array<any>;

  @Prop({ type: String, required: true })
  valueField!: string;

  @Prop({ type: String, required: true })
  displayField!: string;

  @Prop({ type: String, default: () => "列表" })
  leftHeaderText!: string;

  @Prop({ type: String, default: () => "已选择" })
  rightHeaderText!: string;

  refreshFlag = 0;

  realListData: Array<any> = [];
  realSelectedItems: Array<any> = [];

  leftSelectedItem: Map<string, any> = new Map();
  rightSelectedItem: Map<string, any> = new Map();

  created() {
    this.realListData = JSON.parse(JSON.stringify(this.source));
    this.realSelectedItems = JSON.parse(JSON.stringify(this.selectedItems));
  }

  setLeftSelection(data: any, event: MouseEvent) {
    if (!event.ctrlKey) {
      this.leftSelectedItem.clear();
    }
    this.leftSelectedItem.set(data[this.valueField], data);
    this.doListUpdate();
  }

  setRightSelection(data: any, event: MouseEvent) {
    if (!event.ctrlKey) {
      this.rightSelectedItem.clear();
    }
    this.rightSelectedItem.set(data[this.valueField], data);
    this.doListUpdate();
  }

  moveToRight() {
    if (!this.leftSelectedItem.size) {
      return;
    }
    this.leftSelectedItem.forEach(item => {
      const pos = this.realListData.indexOf(item);
      if (pos === -1) {
        return;
      }
      this.realListData.splice(pos, 1);
      this.realSelectedItems.push(item);
    });
    this.leftSelectedItem.clear();
    this.doListUpdate();
  }

  moveToLeft() {
    if (!this.rightSelectedItem.size) {
      return;
    }
    this.rightSelectedItem.forEach(item => {
      const pos = this.realSelectedItems.indexOf(item);
      if (pos === -1) {
        return;
      }
      this.realSelectedItems.splice(pos, 1);
      this.realListData.push(item);
    });
    this.rightSelectedItem.clear();
    this.doListUpdate();
  }

  moveAllToRight() {
    if (!this.realListData || !this.realListData.length) {
      return;
    }
    const allItems: Array<any> = ([] as Array<any>).concat(this.realListData);
    this.realListData = [];
    allItems.forEach(item => this.realSelectedItems.push(item));
    this.leftSelectedItem.clear();
    this.doListUpdate();
  }

  moveAllToLeft() {
    if (!this.realSelectedItems || !this.realSelectedItems.length) {
      return;
    }
    const allItems: Array<any> = ([] as Array<any>).concat(
      this.realSelectedItems
    );
    this.realSelectedItems = [];
    allItems.forEach(item => this.realListData.push(item));
    this.rightSelectedItem.clear();
    this.doListUpdate();
  }

  selectLeftAll(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.leftSelectedItem.clear();
    this.realListData.forEach(data =>
      this.leftSelectedItem.set(data[this.valueField], data)
    );

    this.doListUpdate();
  }

  selectRightAll(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.rightSelectedItem.clear();
    this.realSelectedItems.forEach(data =>
      this.rightSelectedItem.set(data[this.valueField], data)
    );

    this.doListUpdate();
  }

  doListUpdate() {
    this.refreshFlag += 1;
  }

  getSelectedItems() {
    return this.realSelectedItems;
  }

  getListData() {
    return this.realListData;
  }
}
