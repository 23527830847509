import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faTimes,
  faCloudUploadAlt,
  faPlusCircle,
  faMinusCircle,
  faSync,
  faAngleDoubleRight,
  faHome
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default function initFonts() {
  library.add(
    faTimes,
    faCloudUploadAlt,
    faPlusCircle,
    faMinusCircle,
    faCheck,
    faSync,
    faAngleDoubleRight,
    faHome
  );
  Vue.component("FontAwesomeIcon", FontAwesomeIcon);
}
