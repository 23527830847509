
import message from "./modules/views/message";
import patent from "./modules/views/patent";
import systemlog from "./modules/views/systemlog";
import users from "./modules/views/users";
import activity from "./modules/views/activity";

const modules: { [key: string]: any } = {
  patent,
  users,
  activity,
  message,
  systemlog
};

export default modules;
