import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/stores";

import "./styles/index.scss";

import initFonts from "./configs/FontConfigs";
import initComps from "./configs/ComponentConfigs";
import dialogDragDirective from "./configs/DialogDragDirective";

initFonts();
initComps();

Vue.config.productionTip = false;

Vue.config.devtools = process.env.NODE_ENV !== "production";

Vue.filter("toObjectString", function (value: any) {
  return JSON.stringify(value || {});
});

Vue.directive("dialogDrag", dialogDragDirective);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
