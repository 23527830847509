



























































import { Vue, Component } from "vue-property-decorator";
import { Fetcher as Ajax } from "@/utils/Request";
import { API_USER_ME } from "@/configs/Apis";
import { showError, showSuccess, showWarning } from "@/utils/Common";
import { MSG_INVALID_PAGE, MSG_MODIFY_SUCCESS } from "@/configs/Consts";
@Component
export default class AccountInfo extends Vue {
  isEditing = false;
  userDataItem: any = {};
  visible = false;
  open() {
    this.visible = true;
    this.userDataItem = this.$store.getters["user/user"] || {};
  }

  onClose() {
    this.userDataItem = {};
    this.visible = false;
  }

  saveMe() {
    const form = this.$refs.userForm as any;
    form.validate((isValid: boolean) => {
      if (isValid) {
        Ajax.saveData(API_USER_ME, this.userDataItem, "PUT")
          .then(() => showSuccess(MSG_MODIFY_SUCCESS))
          .then(() => {
            this.onClose();
          })
          .catch(({ message }) => showError(message));
      } else {
        showWarning(MSG_INVALID_PAGE);
      }
    });
  }
}
