import { API_ENUMS } from "@/configs/Apis";
import { Fetcher } from "@/utils/Request";
import { Module } from "vuex";

const module: Module<{ enums: any }, any> = {
  namespaced: true,
  state: {
    enums: null
  },
  mutations: {
    setEnums(state, data) {
      state.enums = data;
    }
  },

  getters: {
    enums(state) {
      return JSON.parse(JSON.stringify(state.enums));
    },
    getEnumsByKey(state) {
      return (key: string) => state.enums[key];
    }
  },

  actions: {
    getEnums({ commit }) {
      const api = `${API_ENUMS}?_=${new Date().valueOf()}`;
      const task = [
        Fetcher.queryData(api, {}, "GET")
      ];
      return Promise.all(task).then(([enumsData]) => {
        commit("setEnums", enumsData);
      }).catch(() => "");
    }
  }
};

export default module;
